.tabs {
  display: flex;
  list-style: none;
  padding: 0;
}

.tab {
  margin-right: 10px;
  cursor: pointer;
}

.content {
  display: none;
}

.content.active {
  display: block;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

::-webkit-scrollbar {
  width: 2px;
}

.colorlib-nav-toggle:hover, .colorlib-nav-toggle:focus, .colorlib-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

a:hover, a:active, a:focus {
  color: #2c98f0;
  outline: none;
  text-decoration: none !important;
}

.collapse1{
  display: block;
}

.desc1{
  display: flex;
    width: 850px;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-group{
  padding-bottom: 15px;
}

@media screen and (max-device-width: 480px) and (orientation: portrait){

.collapse1{
  display: none;
}

.collapse1.is-open{
  display: block;
}



 .aside1 {
   display: none;
 }

 .aside1.is-open {
  display: block;
  position: relative;
 }

 .desc1{
  display: flex;
  width: auto;
 }

 #colorlib-hero {
  margin-bottom: 0;
 }
}