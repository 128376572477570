.owl-theme .owl-controls{margin-top:10px;text-align:center;-webkit-tap-highlight-color:transparent}.owl-theme .owl-controls .owl-nav [class*=owl-]{color:#fff;font-size:14px;margin:5px;padding:4px 7px;background:#d6d6d6;display:inline-block;cursor:pointer;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px}.owl-theme .owl-controls .owl-nav [class*=owl-]:hover{background:#869791;color:#fff;text-decoration:none}.owl-theme .owl-controls .owl-nav .disabled{opacity:.5;cursor:default}.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1;*display:inline}.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#d6d6d6;display:block;-webkit-backface-visibility:visible;-webkit-transition:opacity 200ms ease;-moz-transition:opacity 200ms ease;-ms-transition:opacity 200ms ease;-o-transition:opacity 200ms ease;transition:opacity 200ms ease;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px}.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}

/*.owl-theme .owl-controls {
    margin-top:10px;
    text-align:center;
    -webkit-tap-highlight-color:transparent
}
.owl-theme .owl-controls .owl-nav[class*=owl-] {
    color:#fff;
    font-size:14px;
    margin:5px;
    padding:4px 7px;
    background:#d6d6d6;
    display:inline-block;
    cursor:pointer;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    border-radius:3px
}
.owl-theme .owl-controls .owl-nav[class*=owl-]:hover {
    background:#869791;
    color:#fff;
    text-decoration:none
}
.owl-theme .owl-controls .owl-nav .disabled {
    opacity:.5;
    cursor:default
}
.owl-theme .owl-dots .owl-dot {
    display:inline-block;
    zoom:1;
    *display:inline
}
.owl-theme .owl-dots .owl-dot span {
    width:10px;
    height:10px;
    margin:5px 7px;
    background:#d6d6d6;
    display:block;
    -webkit-backface-visibility:visible;
    -webkit-transition:opacity 200ms ease;
    -moz-transition:opacity 200ms ease;
    -ms-transition:opacity 200ms ease;
    -o-transition:opacity 200ms ease;
    transition:opacity 200ms ease;
    -webkit-border-radius:30px;
    -moz-border-radius:30px;
    border-radius:30px
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background:#869791
}*/